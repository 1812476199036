import React from "react"
import { Helmet } from "react-helmet";
import { motion } from "framer-motion"
import { use100vh } from "react-div-100vh"
import Header from "../components/Header";
import Logo from "../components/Logo";

export default function Home() {
  const page = {
    visible: { 
      opacity: 1,
      transition: {
        when:"beforeChildren",
        staggerChildren: 0.5,
      }
    },
    hidden: { 
      opacity: 0,
      transition: {
        when: "afterChildren",
      }
    },
  }
  const logo = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  return (
    <motion.div
      className="bg-primary flex relative p-0"
      style={{ height: use100vh() }}
      initial="hidden"
      animate="visible"
      variants={page}
    >
      <Helmet>
      <meta name="facebook-domain-verification" content="l7a3itzkn79wph9w3doxm1pv8o6sl3" />
      </Helmet>
      <div className="absolute top-0">
        <Header isHome/>
      </div>
      <motion.div className=" m-auto w-8/12 lg:w-logo " variants={logo}>
        <Logo />
      </motion.div>
    </motion.div>  
  )
}
